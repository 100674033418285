import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import OutsideClickHandler from "react-outside-click-handler";
import { deepClone } from "../../../helper-methods";
import Swal from "sweetalert2";

import {
  deleteRegistration,
  eventRegistration,
  getAllUpcomingEvents,
  getUserRegistrations,
} from "../../../http-calls";
import "./add-to-events-sidedock.scss";
import EmailFormatter from "../../../helper-methods/email-formatter";

const AddToEventsSidedock = ({
  isVisible,
  selectedUser,
  onDismiss = () => {},
  onUpdate = () => {},
}) => {
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [selectedEventSlots, setSelectedEventSlots] = useState({});
  const [existingRegistrations, setExistingRegistrations] = useState([]);
  const [regsToDelete, setRegsToDelete] = useState([]);

  const _fetchAllEvents = async () => {
    const {
      data: { events: allEventSlots },
    } = await getAllUpcomingEvents();
    const allEvents = {};
    const currentYear = "2025";

    allEventSlots.forEach((eventSlot) => {
      if (eventSlot.event_name.indexOf(currentYear) > -1) {
        if (!allEvents[eventSlot.event_id]) {
          allEvents[eventSlot.event_id] = {
            event_name: eventSlot.event_name,
            event_id: eventSlot.event_id,
            isEnabled: eventSlot.isEnabled,
            slots: [],
          };
        }
        const formattedEventSlot = _formatEventSlot(eventSlot);
        allEvents[eventSlot.event_id].slots.push(formattedEventSlot);
      }
    });
    // console.log('data', data)
    setAllEvents(Object.values(allEvents));
  };

  const _formatEventSlot = (eventSlot) => {
    return {
      ...eventSlot,
      label: `${convert24To12(eventSlot.start_time)} EST - ${convert24To12(
        eventSlot.end_time
      )} EST`,
    };
  };

  const _toggleEventSlotSelection = ({ eventSlot, event }) => {
    console.log("eventSlot", eventSlot);
    let _selectedEventSlots = deepClone(selectedEventSlots);
    if (_selectedEventSlots[eventSlot.event_slot_id]) {
      if (_selectedEventSlots[eventSlot.event_slot_id].registration_id) {
        // Add to delete list
        setRegsToDelete([
          ...regsToDelete,
          _selectedEventSlots[eventSlot.event_slot_id].registration_id,
        ]);
      }
      delete _selectedEventSlots[eventSlot.event_slot_id];
    } else {
      // Check if selected for same event
      let otherSlotIdFromSameEvent = null;
      const allSlotIds = event.slots.map((slot) => slot.event_slot_id);
      allSlotIds.forEach((allSlotId) => {
        if (_selectedEventSlots[allSlotId]) {
          otherSlotIdFromSameEvent = allSlotId;
        }
      });
      if (otherSlotIdFromSameEvent) {
        if (_selectedEventSlots[otherSlotIdFromSameEvent].registration_id) {
          // Add to delete list
          setRegsToDelete([
            ...regsToDelete,
            _selectedEventSlots[otherSlotIdFromSameEvent].registration_id,
          ]);
        }
        delete _selectedEventSlots[otherSlotIdFromSameEvent];
      }
      _selectedEventSlots[eventSlot.event_slot_id] = eventSlot;
    }
    console.log("_selectedEventSlots :>> ", _selectedEventSlots);
    setSelectedEventSlots(_selectedEventSlots);
  };

  const convert24To12 = (time24) => {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  };

  const _preparePayload = () => {
    console.log("selectedEventSlots :>> ", selectedEventSlots);
    return Object.values(selectedEventSlots)
      .filter((regs) => !regs.registration_id)
      .map((eventSlot) => ({
        userId: selectedUser.user_id,
        eventId: eventSlot.event_id,
        eventSlotId: eventSlot.event_slot_id,
        focusId: eventSlot.focus_id,
      }));
  };

  const _prepareEmailsPayload = () => {
    const allPayloads = Object.values(selectedEventSlots)
      .filter((regs) => !regs.registration_id)
      .map((eventSlot) => ({
        event: {
          eventName: eventSlot.event_name,
          startDate: eventSlot.start_date,
          endDate: eventSlot.end_date,
        },
        selectedTimeslot: {
          startTime: eventSlot.start_time,
          endTime: eventSlot.end_time,
        },
        userEmail: selectedUser.email,
      }));
    return allPayloads;
  };

  const _saveChanges = async () => {
    setIsLoaderActive(true);
    const allPayloads = _preparePayload();
    try {
      // console.log('allPayloads :>> 2222290', allPayloads);
      // console.log('regsToDelete :>> 2222290', regsToDelete);
      const uniqueRegsToDelete = [...new Set(regsToDelete)];
      await Promise.allSettled(
        uniqueRegsToDelete.map((regId) =>
          deleteRegistration({
            registrationId: regId,
          })
        )
      );
      if (allPayloads.length) {
        await Promise.allSettled(
          allPayloads.map((payload) => eventRegistration(payload))
        );
        // Send email
        const emailPayloads = _prepareEmailsPayload();
        await Promise.allSettled(
          emailPayloads.map((payload) =>
            EmailFormatter.sendEventRegistrationEmail(payload)
          )
        );

        Swal.fire(
          "Successfully registered to the events",
          "You can check the registration details in the 'All Regsitrations' section",
          "success"
        );
        onUpdate();
      }
    } catch (error) {
      console.log("error", error);
      Swal.fire("Unable to register to the events", "", "error");
    }
    setIsLoaderActive(false);
  };

  const _tryToDismiss = () => {
    if (!isLoaderActive) {
      onDismiss();
    } else {
      // alert('Please wai')
    }
  };

  const _getUserRegistrations = async () => {
    console.log('selectedUser :>> ', selectedUser);
    const response = await getUserRegistrations(selectedUser.user_id);
    if (response?.data?.registeredEvents) {
      const allSlotIds = response?.data?.registeredEvents.map(
        (registration) => registration.event_slot_id
      );
      setExistingRegistrations(allSlotIds);
      // Now set the selected slots
      const _selectedEventSlots = {};
      allSlotIds.forEach((slotId) => {
        _selectedEventSlots[slotId] = response?.data?.registeredEvents.find(
          (event) => event.event_slot_id === slotId
        );
      });
      setSelectedEventSlots(_selectedEventSlots);
    } else {
      setExistingRegistrations([]);
    }
  };

  const _resetAll = () => {
    setSelectedEventSlots({});
    setExistingRegistrations([]);
    setRegsToDelete([]);
  };

  useEffect(() => {
    _fetchAllEvents();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      setIsLoaderActive(false);
      _getUserRegistrations();
    } else {
      _resetAll();
    }
  }, [selectedUser]);

  useEffect(() => {
    if (!isVisible) {
      setSelectedEventSlots({});
    }
  }, [isVisible]);

  return (
    <>
      <div className={`eventSideBarOuterWrapper ${isVisible ? "show" : ""}`}>
        <OutsideClickHandler onOutsideClick={_tryToDismiss}>
          <div className={`eventSideBarWrapper ${isVisible ? "show" : ""}`}>
            {isLoaderActive ? (
              <div className="loaderWrapper">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true}
                />
              </div>
            ) : (
              <>
                {selectedUser ? (
                  <>
                    <div className="valueDisplay">
                      <div className="label">Name</div>
                      <div className="value">{selectedUser?.name}</div>
                    </div>

                    {allEvents?.map((event) => (
                      <React.Fragment key={event.event_id}>
                        <div className="valueDisplay">
                          <div className="label eventName">
                            {event?.event_name}
                          </div>
                        </div>

                        <div className="valueDisplay">
                          {/* <div className="label">Timeslot</div> */}
                          <div className="slotSelector">
                            {event?.slots?.map((slot) => (
                              <div
                                className={`slot ${
                                  selectedEventSlots[slot.event_slot_id]
                                    ? "active"
                                    : ""
                                }`}
                                key={slot.label}
                                onClick={(e) =>
                                  _toggleEventSlotSelection({
                                    eventSlot: slot,
                                    event,
                                  })
                                }
                              >
                                {slot.label}
                              </div>
                            ))}
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <></>
                )}
                <div className="bottomActionBar">
                  <button className="cancelBtn" onClick={_tryToDismiss}>
                    Close
                  </button>
                  <button
                    className="saveBtn"
                    disabled={Object.keys(selectedEventSlots).length < 1}
                    onClick={_saveChanges}
                  >
                    Register
                  </button>
                </div>
              </>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
};

export default AddToEventsSidedock;
