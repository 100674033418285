// src/helpers/ProfileHelper.js

import { updateProfileData } from "../../http-calls";

const ProfileHelper = {
  // Function to check if AOP fields are filled in the profile
  checkIfAOPisFilled: (profile) => {
    const filledAopDetails = profile.isAopMember !== "NO_INPUT";
    return filledAopDetails;
  },

  // Function to update the profile by calling the backend
  updateProfile: async ({
    profile,
    isAopMember,
    aopNumber,
  }) => {
    try {
        const payload = {
            firstName: profile.first_name,
            lastName: profile.last_name,
            email: profile.email,
            state: profile.state,
            zip: profile.zip,
            country: profile.country,
            companyName: profile.company_name,
            phone: profile.phone,
            isAopMember,
            aopNumber,
        }
      // API call to update profile
      await updateProfileData(payload);
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error; // Rethrow the error to handle it in the component
    }
  },
};

export default ProfileHelper;
