import React from "react";
import DataTable from "react-data-table-component";
import { deepClone } from "../../../helper-methods";
import { getAllUpcomingEvents, getAllUsers } from "../../../http-calls/index";
import AddToEventsSidedock from "../../components/add-to-events-sidedock/add-to-events-sidedock";

import "./non-registered-users-page.scss";

class NonRegisteredUsersPage extends React.Component {
  state = {
    allRegistrations: [],
    allNonRegisteredUsers: [],
    filteredUsers: [],
    searchText: "",
    eventEditor: {
      isVisible: false,
      selectedUser: null,
    },
  };

  columns = [
    {
      name: "Name",
      selector: (row) => row["name"],
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row["phone"],
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row) => row["company_name"],
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => row["state"],
      sortable: true,
    },
    {
      name: "Zip",
      selector: (row) => row["zip"],
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => row["country"],
      sortable: true,
    },
    {
      name: "Add to events",
      selector: (row) => row["zip"],
      cell: (row, index, column, id) => (
        <button
          className="deleteRegBtn"
          onClick={(e) => this._showAddToEventModal(row)}
        >
          <i className="fas fa-plus"></i>
        </button>
      ),
      sortable: true,
    },
  ];

  componentDidMount() {
    this._loadAllRegistrations();
  }

  _showAddToEventModal = (user) => {
    this.setState({
      eventEditor: {
        isVisible: true,
        selectedUser: user,
      },
    });
  };

  _hideAddToEventModal = () => {
    this.setState({
      eventEditor: {
        isVisible: false,
        selectedUser: null,
      },
    });
  };

  _filterNonRegisteredUsers = ({ allUsers, allEvents }) => {
    const idMappedCurrentYearEvents = {};
    const currentYear = '2025';
    for (let event of allEvents) {
      if (event.event_name.indexOf(currentYear) > -1) {
        idMappedCurrentYearEvents[event.event_id] = event;
      }
    }
    return allUsers
      .filter((user) => {
        let hasCurrentYearRegistration = false;
        if (user?.registrations?.length) {
          user.registrations.forEach((reg) => {
            if (idMappedCurrentYearEvents[reg.event_id]) {
              hasCurrentYearRegistration = true;
            }
          });
        }
        return !hasCurrentYearRegistration;
      })
      .map((user) => user.user)
      .map((user) => ({
        name: `${user.first_name} ${user.last_name}`,
        ...user,
      }));
  };

  _loadAllRegistrations = async () => {
    try {
      const {
        data: { allUsers },
      } = await getAllUsers();
      const {
        data: { events: allEvents },
      } = await getAllUpcomingEvents();
      const nonRegisteredUsers = this._filterNonRegisteredUsers({
        allUsers,
        allEvents,
      });
      console.log("nonRegisteredUsers", nonRegisteredUsers);
      this.setState({
        allNonRegisteredUsers: nonRegisteredUsers,
      });
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  _filter = (allRows) => {
    const excludeInSearch = ["battery", "user_id", "role", "registered_at"];
    let filteredRows = deepClone(allRows);
    const { searchText } = this.state;
    // First filter by search text
    const trimmedSearchText = searchText.trim();
    if (trimmedSearchText.length) {
      filteredRows = filteredRows.filter((r) => {
        let isValid = false;
        const allDataPoints = Object.keys(r);
        allDataPoints.forEach((dataPoint) => {
          if (excludeInSearch.indexOf(dataPoint) === -1) {
            if (
              r[dataPoint] &&
              r[dataPoint]
                .toLowerCase()
                .indexOf(trimmedSearchText.toLowerCase()) > -1
            ) {
              isValid = true;
            }
          }
        });
        return isValid;
      });
    }
    return filteredRows;
  };

  _removeUser = () => {
    const {
      eventEditor: { selectedUser },
      allNonRegisteredUsers,
    } = this.state;
    if (selectedUser) {
      const index = allNonRegisteredUsers.findIndex(
        (u) => u.user_id === selectedUser.user_id
      );
      if (index > -1) {
        allNonRegisteredUsers.splice(index, 1);
      }
    }
    this.setState({ allNonRegisteredUsers }, () => {
      this._hideAddToEventModal();
    });

  };

  render() {
    const { allNonRegisteredUsers, searchText, eventEditor } = this.state;

    const filteredRows = this._filter(allNonRegisteredUsers);

    return (
      <div className="adminPageWrapper">
        <div className="pageTitle">Users with no event for 2025</div>
        <div className="tableActions">
          <div className="leftPart">
            <input
              type="text"
              placeholder="Search"
              value={searchText}
              onChange={(e) => this.setState({ searchText: e.target.value })}
            />
          </div>
          <div className="rightPart"></div>
        </div>
        <DataTable
          columns={this.columns}
          data={filteredRows}
          fixedHeader
          pagination
          paginationPerPage={100}
          paginationRowsPerPageOptions={[100, 500, 1000]}
        />
        <AddToEventsSidedock
          isVisible={eventEditor.isVisible}
          selectedUser={eventEditor.selectedUser}
          onDismiss={this._hideAddToEventModal}
          onUpdate={this._removeUser}
        />
      </div>
    );
  }
}

export default NonRegisteredUsersPage;
