export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCQkBaIwGEjQx9TH5S_YMUQCmAErnVwVEA",
  authDomain: "portal.concordebattery.com",
  projectId: "concordebatteryiarenewal",
  storageBucket: "concordebatteryiarenewal.appspot.com",
  messagingSenderId: "1069254668757",
  appId: "1:1069254668757:web:68984179df25204da64d5c",
};
// export const BASE_URL = "https://7b94-2401-4900-1c00-7476-739b-f15c-af38-83dc.in.ngrok.io";
export const BASE_URL = "https://concordeportal.dextech-qa.com";
// export const BASE_URL = "http://localhost:3010";